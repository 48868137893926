import Countries from "./components/Countries";
import './App.css'

function App() {
  return (
   <Countries/>
  );
}
export default App;

